/* css module styled - qr-code.module.css */

.container {
    position: relative;
    width: 100%;
    height: 167px;
}

.qrCode {
    margin: 0 5px 5px 5px;
    width: 168px;
    height: 168px;
}

.buttonToLink {
    font-family: Verdana, sans-serif;
    font-size: 13px;
    text-align: left;
    color: #009ae4;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    background-color: transparent;
}

.reGenerateWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 33%;
}

.reGenerateButton {
    font-family: Inter-SemiBold, Inter-Semi-Bold, Inter, sans-serif;
    background-color: #003DA5;
    font-size: 14px;
    border: 1px solid #003DA5;
    margin: 2px;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 25px;
    border-radius: 50px;
    color: #fff;
}

.reGenerateButtonText {
    display: flex;
    align-items: center;
}

.recurringIcon {
    margin-left: 10px;
}
