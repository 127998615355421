:root {
    --Inter: "Inter-Regular", "Inter Regular", "InterRegular", "Inter", sans-serif;
    --InterSemiBold: "Inter-SemiBold", "Inter SemiBold", "InterSemiBold", "Inter", sans-serif;
    --InterMedium: "Inter-Medium", "Inter Medium", "InterMedium", "Inter", sans-serif;
    --InterBold: "Inter-Bold", "Inter Bold", "InterBold", "Inter", sans-serif;
    --Stag: "Stag", sans-serif;
    --StagMedium: "Stag-Medium", "Stag Medium", "StagMedium", "Stag", sans-serif;
    --StagBold: "Stag-Bold", "Stag Bold", "StagBold", "Stag", sans-serif;
    --Brand700-base: #003DA5;
    --Blue205: #00205B;
    --containerOffWhite: #f6f8fa;
    --Black900: #0A1F44;
    --obsidian: #000000;
    --black1: #191919;
    --Gray200: #f7f8f9;
    --gray2: #4D515B;
    --gray3: #C9CED6;
    --bannerBoxSucccess: rgba(0,134,90,0.1);
    --bannerBoxSuccess-border: #00865A;
    --bannerBoxBlue: rgba(0,154,228,0.1);
    --bannerBoxBlue-border: #009AE4;
    --whiteBox-border: #E0E3E7;
    --white: #ffffff;
    --black: #000000;
    --lightGray: #8A94A6;
    --boxShadow: rgba(0,0,0,0.07);
    --listTextGray: #4D515B;
    --red: #A50000;
    --redDiffusedBG: rgba(165,0,0,0.1);
    --green: #00865A;
    --greenDiffusedBG: #e6f2ee;
    --blueDiffusedBG: rgba(0,61,165,0.08);
    --mintgreen: #00C457;
    --mintgreenDiffusedBG: rgba(0,77,34,0.08);
    --tertiary900: #00205B;
}

/*triggers only when modal is turned on, prevents scroll of body*/
body.modal-enabled {
    overflow-y: hidden;
}
body.modal-enabled .modal-container {
    overflow-y: scroll;
}
.hidden {
    display: none;
}

.auto-renewal.detail-page .container {
    padding-bottom: 10px;
}
.auto-renewal.detail-page .main-content-container-boxed {
    background: var(--containerOffWhite);
    margin-bottom: 0;
    padding-bottom: 50px;
    /* background: #ff0000; */
}
.auto-renewal .main-content-container-boxed .main-content-box:first-child {
    margin-top: 40px;
}
.auto-renewal .main-content-title {
    color: var(--obsidian);
    font-size: 24px;
}
.auto-renewal .main-content-title.top-title {
    color: var(--Brand700-base);
    font-size: 40px;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
}
.auto-renewal .main-content-box {
    border-radius: 20px;
    box-shadow: 2px 2px 10px 0 var(--boxShadow);
    padding: 0;
    color: var(--Black900);
    font-family: var(--Inter);
    line-height: 24px;
}
.auto-renewal .main-content-box .content-boxed-block {
    padding: 30px 50px;
}
.main-content-box .content-row {
    display: flex;
}
.main-content-box .content-row > div {
    width: 50%;
    align-items: center;
    height: 40px;
}
.main-content-box .content-row .content-right {
    font-weight: bold;
}
.status-block {
    background: var(--Gray200);
}
.banner-box {
    padding: 20px;
    display: flex;
    gap: 20px;
    align-items: flex-start;
}
.banner-box.on {
    border: 1px solid var(--green);
    background: var(--greenDiffusedBG);
}
.banner-box.on::before {
    content: url('../portfolio/icon_check.svg');
    width: 24px;
}
.banner-box.off {
    background: var(--bannerBoxBlue);
    border: 1px solid var(--bannerBoxBlue-border);
}
.banner-box.off::before {
    content: url('../icon-info_small.svg');
    width: 24px;
}
.banner-box-details {
    display: block;
}
.banner-box-details .status-title {
    font-family: var(--InterBold);
    margin-bottom: 5px;
    font-size: 14px;
}
.banner-box-details .details-text {
    font-family: var(--InterMedium);
    font-size: 14px;
}
.banner-box .banner-box-icon  {
    width: 24px;
}
.details-box {
    background: #fff;
    border: 1px solid var(--whiteBox-border);
    padding: 30px;
}
.status-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}
.status-row h1 {
    font-family: var(--InterBold);
    font-size: 18px;
    color: var(--Blue205);
    line-height: 28px;
}

.btn {
    padding: 8px 25px;
    height: 65px;
    color: var(--white);
    font-size: 16px;
    font-family: var(--InterSemiBold);
    line-height: 22px;
    border: 1px solid var(--Brand700-base);
    min-width: 140px;
}
.btn-round {
    border-radius: 50px;
}
.btn-primary {
    background-color: var(--Brand700-base);
}
.btn-secondary {
    background-color: var(--white);
    color: var(--Brand700-base);
}
.btn-small {
    padding: 5px 18px !important;
    height: unset;
    font-size: 14px;
    line-height: 20px;
}
.status-row button,
.form-container button{
    box-shadow: none;
}
/*overrides button component style*/
.btn-big {
    min-width: 160px;
    padding: 20px 45px;
    font-family: var(--InterSemiBold) !important;

}
.btn-containerWidth {
    min-width: 350px;
}
.bottom-block-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
}
.main-list {
    margin: 30px 0;
}
.main-list ul {
    list-style-type: none;
    padding-left: 0;
}
.main-list ul li {
    padding-left: 30px;
    margin-bottom: 20px;
    font-family: var(--Inter);
}
.details-box .main-list ul li {
    display: flex;
    padding-left: 0;
}
.details-box .main-list.optIN ul li:before {
    content: url(../list-tick-green.svg);
    margin-right: 12px;
    margin-top: 3px;
}
.details-box .main-list.optOUT ul li:before {
    content: url(../info_blue-fill.svg);
    margin-right: 12px;
}
.FlipArrow {
    transform: rotate(180deg);
}
.TOS-list {
    padding: 10px 20px;
    border: 1px solid var(--whiteBox-border);
    font-size: 12px;
    font-family: var(--InterMedium);
    line-height: 20px;
}
.TOS-list .Toggler {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.TOS-list ol,
.TOS-list ul {
    font-size: 12px;
    line-height: 18px;
    padding-left: 15px;
}
.TOS-list ol li,
.TOS-list ul li {
    margin: 10px 0;
}
.or-container {
    margin-top: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid var(--black);
    position: relative;
}
.or-text {
    width: 70px;
    height: 40px;
    background: var(--containerOffWhite);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -20px;
    left: calc(50% - 35px);
    font-family: var(--InterSemiBold);
    font-size: 16px;
}
.no-block-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: var(--InterSemiBold);
    font-size: 14px;
    line-height: 22px;

    color: var(--Black900);
}
.avatar-icon img {
    width: 70px;
    border-radius: 60px;
    padding: 3px;
    box-shadow: 0 2px 15px var(--boxShadow);
}
.intermediary-block {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
}
.intermediary-block-title {
    font-family: var(--StagMedium);
    color: var(--Brand700-base);
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px;
}
.name {
    font-family: var(--InterSemiBold);
    font-size: 16px;
    line-height: 24px;
    color: var(--Black900);
    margin-bottom: 8px;
}
.designation {
    font-family: var(--Inter);
    font-size: 16px;
    line-height: 20px;
    color: var(--lightGray);
    margin-bottom: 15px;
}
.intermediary-contact-details {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
}
.mobile, .email {
    font-family: var(--Inter);
    font-size: 16px;
    line-height: 20px;
    color: var(--Black900);
    display: flex;
    align-items: center;
}
.agent-mobile::before {
    width: 23px;
    margin-right: 10px;
    content: url("../icon_phone.svg");
}
.agent-email::before {
    width: 19px;
    margin-right: 10px;
    content: url("../icon_mail.svg");
}
.auto-renewal-fields {
    margin-top: 55px;
}

/* Modals */
.modal-container {
    width: 100%;
    height: 100vh;
    margin-top: -40px;
    display: flex;
    justify-content: center;
    position: fixed;
    background: rgba(0,0,0,0.7);
    z-index: 999;
}
.modal-block {
    border-radius: 20px;
    padding: 40px 60px;
    background: #ffffff;
    height: auto;
    max-width: 630px;
    margin-top: 100px;
}
.modal-top {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}
.modal-title {
    font-family: var(--StagMedium);
    font-size: 24px;
    line-height: 28px;
    color: var(--Brand700-base);
}
.modal-body {
    font-family: var(--Inter);
    font-size: 16px;
    line-height: 24px;
    color: var(--black);
}
.note-top {
    text-align: center;
    margin-bottom: 30px;
}
.modal-body .list-text {
    font-size: 14px;
    line-height: 21px;
    color: var(--listTextGray);
    margin-bottom: 20px;
}
.modal-body .list-text li {
    margin-bottom: 10px;
}
.modal-bottom {
    margin-top: 40px;
    margin-bottom: 20px;
}
.modal-bottom .button-container {
    display: flex;
    justify-content: center;
    gap: 25px;
}

/* MANAGE AUTO-RENEWAL */
#topAlert {
    margin-top: 40px;
}
.alert-container {
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
}
.status-container {
    height: 34px;
    min-width: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
}
.alert-container.info-alert {
    font-size: 14px;
    font-family: var(--InterMedium);
    line-height: 18px;
}
.error-alert {
    border: 1px solid var(--red);
    background-color: var(--redDiffusedBG);
}
.alert-container.info-alert::before {
    content: url("../portfolio/icon_check.svg");
    width: 16px;
    height: 16px;
    line-height: 0;
    display: flex;
    align-items: center;
}
.alert-container.error-alert::before {
    content: url("../icon-info-red.svg");
    width: 16px;
    height: 16px;
    line-height: 0;
    display: flex;
    align-items: center;
}
.info-alert {
    border: 1px solid var(--green);
    background-color: var(--greenDiffusedBG);
}
.active-alert {
    border: 1px solid var(--mintgreen);
    background-color: var(--mintgreenDiffusedBG);
}
.inactive-alert {
    border: 1px solid var(--Brand700-base);
    background-color: var(--blueDiffusedBG);
}
.alert-container.error-alert,
.alert-container.active-alert {
    border-radius: 0;
    font-size: 14px;
    font-family: var(--Inter);
    line-height: 20px;
    color: var(--black1);
}
.status-container.error-alert,
.status-container.active-alert,
.status-container.inactive-alert {
    border-width: 2px;
    border-radius: 6px;
    font-size: 12px;
    font-family: var(--InterSemiBold);
    line-height: 18px;
}
.status-container.active-alert {
    color: var(--mintgreen);
}
.status-container.inactive-alert {
    color: var(--Brand700-base);
}
.status-container.error-alert {
    color: var(--red);
}
.info-container {
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    margin-top: 20px;
    background: var(--white);
}
.info-container .infoPadded-container {
    padding: 30px 45px;
}
.info-container .info-sub-container {
    width: 100%;
    padding: 25px;
    background-color: var(--Gray200);
}
.lined-title {
    font-family: var(--InterSemiBold);
    font-size: 18px;
    line-height: 27px;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--black);
    width: 100%;
}
.linked-title,
.linked-title a,
.linked-title a:link,
.linked-title a:hover,
.linked-title a:visited {
    color: var(--Brand700-base);
}
.status-display-title {
    width: 100%;
    font-family: var(--InterBold);
    font-size: 14px;
    margin-bottom: 10px;
}
.status-display > span {
    font-family: var(--InterBold);
    font-size: 14px;
    color: var(--black);
}
.data-row {
    display: flex;
    flex-wrap: wrap;
}
.data-row span {
    color: var(--gray2) !important;
    font-size: 14px;
    font-family: var(--Inter);
    line-height: 21px;
    width: 100%;
}
.data-row span.data-value,
.data-row div.data-value {
    font-family: var(--InterBold);
    color: var(--black) !important;
    font-size: 16px;
    line-height: 24px;
}
.hiddenVal:before {
    content: "xxxx";
    font-family: "Inter-Bold", "Inter Bold", "Inter", sans-serif;
}
.creditCard-section .card-details > div {
    margin-bottom: 20px !important;
}
.card-details > div:last-of-type {
    margin-bottom: 0 !important;
}
.status-row .btn {
    font-family: var(--InterSemiBold);
    border: 1px solid var(--Brand700-base);
    text-transform: unset;
    letter-spacing: 0;
    min-width: unset;
    font-size: 12px;
    height: auto;
}
.status-row .btn-small {
    padding: 8px 16px !important;
}
.status-row .btn.btn-primary {
    color: var(--white);
    background-color: var(--Brand700-base) !important;
}
.status-row .btn.btn-secondary {
    color: var(--Brand700-base);
    background-color: var(--white);
}
.content-boxed-block .info-sub-container {
    width: 100%;
    padding: 25px;
    background-color: var(--Gray200);
}
section.main-info {
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
section.main:last-child {
    padding-bottom: 0;
}
section.separated {
    border-bottom: 1px solid var(--gray3);
}
.manageAutoRenewalDetails-section .main-list {
    margin: 0;
}
.manageAutoRenewalDetails-section .main-list ul {
    list-style-type: unset;
    padding-left: 20px;
    font-size: 14px;
    margin: 0;
}
.manageAutoRenewalDetails-section .main-list ul li {
    background: none;
    padding-left: 0;
    margin-bottom: 15px;
}
.manageAutoRenewalDetails-section .main-list ul li:last-child {
    margin-bottom: 0;
}
.manageAutoRenewalDetails-section .TOS-list {
    background: var(--white);
    margin-top: 20px;
}
.centered-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    font-family: var(--StagMedium);
    font-size: 24px;
    color: var(--tertiary900);
    line-height: 28px;
    letter-spacing: 1px;
}
.centered-title.main-content-title.no-margin {
    margin: 0;
}
.centered-title.noBP {
    padding-bottom: 0;
}
.sub-title {
    font-family: var(--InterBold) !important;
    font-size: 20px !important;
    letter-spacing: 0 !important;
}
.infoPadded-container .sub-title {
    padding: 0 0 30px 0;
}
.card-type-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    height: 75px;
}
.cc-icon {
    width: 80px;
    height: 45px;
}
.cc-icon.visa {
    background: url(../Visa_logo.svg) center center no-repeat;
}
.cc-icon.mastercard {
    background: url(../Mastercard_logo.svg) center center no-repeat;
}
.credit-card-fields .form-field-container {
    display: flex;
    gap: 40px;
}
.cc-note {
    border-top: 1px solid var(--gray3);
    padding-top: 30px;
}
.btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    margin-top: 40px;
    margin-bottom: 20px;
}
.confirm-email-note {
    width: 100%;
    margin-bottom: 20px;
}
.lined-table .content-row {
    border-bottom: 1px solid var(--gray3);
    height: 50px;
    align-items: center;
}
.lined-table .content-row:last-child {
    border-bottom: 0;
}
.lined-table .content-row > div {
    display: flex;
    align-items: center;
    text-align: right;
}
.lined-table .content-left {
    font-size: 14px;
}
.lined-table .content-right {
    justify-content: flex-end;
}

/* Show/Hide Modal CSS adjustments */
.ModalContainerClass {
    align-items: flex-start !important;
    margin-top: 80px !important;
}
.ModalContainerClass div[data-focus-lock-disabled=false] .ModalBody {
    padding: 0;
    margin-bottom: 20px;
}
.ModalContainerClass .ModalContent {
    padding: 0 !important;
}
.ModalContainerClass .modal-block {
    margin-top: 0;
}
.ModalContainerClass .modal-bottom {
    margin-bottom: 0;
}
/*will be hidden when Cancel button is working*/
/*.ModalContainerClass .modalCloseButton {*/
/*    display: none;*/
/*}*/


/* Dashboard Policy/Quote Cards */
.expiring {
    border: 1px solid var(--red);
}
.status-label.expires-soon {
    border: 1px solid var(--red);
    background: var(--redDiffusedBG);
}
.status-label.active {
    border: 1px solid var(--green);
    background: var(--greenDiffusedBG);
}
.status-label.inactive {
    border: 1px solid var(--Blue205);
    background: var(--blueDiffusedBG);
}
.status-label.inactive > div {
    color: var(--Blue205) !important;
}
.icon-manage-autorenewal:hover use {
    fill: red;
}
/* Policy Details */
.statusOn {
    color: var(--mintgreen);
    font-weight: 600;
}
.statusOff {
    color: var(--Black900);
    font-weight: 600;
}

@media only screen and (max-width: 1469px) {
    .pageContainer.auto-renewal .container #mainHeader.main-header {
        padding: 0 0 0 30px;
    }
}

@media only screen and (max-width: 1439px) {
    .pageContainer.auto-renewal .container {
        width: 100%;
    }
}

@media only screen and (max-width: 1199px) {
    .pageContainer.auto-renewal .container {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
    .pageContainer.auto-renewal .titleHeader {
        margin: 0;
        display: flex;
        position: relative;
    }
    .main-content-container-boxed {
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 0;
    }
    .pageContainer.auto-renewal #mainHeader.main-header {
        /*background: red !important;*/
        width: 100px;
        justify-content: flex-end;
        border-bottom: 0;
    }
    .pageContainer.auto-renewal #mainHeader.main-header > div:not(:nth-child(3)){
        display: none;
    }
    .container.header-container {
        display: flex;
        flex-direction: row-reverse;
    }
    .pageContainer.auto-renewal .titleHeader .top-title {
        width: 100%;
        position: absolute;
        top: 0;
        left: 50px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 24px;
    }
    .pageContainer.auto-renewal .auto-renewal-back {
        height: 70px;
    }
    .auto-renewal.detail-page .header-container {
        padding-bottom: 0;
        border-bottom: 1px solid #e1e4e8;
    }
    .pageContainer.auto-renewal .back-label {
        display: none;
    }
    #root {
        background: var(--containerOffWhite);
    }
    .auto-renewal.detail-page .header-container {
        background: var(--white);
    }
    .renewal-notice-page .back-button-container {
        display: none;
    }
    .renewal-notice-page .desktop-header-title {
        display: none;
    }
    .renewal-notice-page .renewal-details-section {
        margin-top: 150px;
    }
    .renewal-notice-page .renewal-notice-page-block {
        padding-left: 10px;
        padding-right: 10px;
    }
    .pageContainer.adhoc-detail-page .topRow {
        margin: 0;
        padding: 40px 166px;
        background: var(--white);
    }
}

@media only screen and (max-width: 767px) {
    .pageContainer.auto-renewal .titleHeader .top-title {
        height: 44px;
        font-size: 24px;
    }
    .pageContainer.auto-renewal .auto-renewal-back {
        height: 44px;
    }
    #root .policy-details-block {
        margin-top: 100px;
    }
    .pageContainer .container .topRow {
        display: flex;
        margin: 0;
        padding: 10px 20px;
        border-bottom: 1px solid #e1e4e8;
        justify-content: space-between;
    }
    .pageContainer .topRow .left img {
        height: 25px;
        width: 87px;
    }
    .pageContainer .container .topRow .left a {
        display: flex;
    }
    .pageContainer .container .topRow .right .languageSelect {
        font-weight: bold;
    }
    .pageContainer.adhoc-detail .main-content-container-box {
        width: 100%;
    }
    .pageContainer.adhoc-detail-page .status-row > h1 {
        width: 100%;
    }
    .ModalContainerClass div[data-focus-lock-disabled="false"] {
        width: 100%;
    }
    .ModalContainerClass .ModalContent .modal-block {
        padding: 10px 20px 20px 20px;
    }
    .modal-bottom .button-container {
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 579px) {
    .pageContainer.auto-renewal .auto-renewal-back {
        font-size: 16px;
    }
    .pageContainer.auto-renewal .auto-renewal-back svg {
        width: 20px;
        height: 20px;
        margin-right: 4px;
    }
    .pageContainer.auto-renewal .credit-card-details .form-field-container label {
        font-size: 14px;
        line-height: 18px;
        top: -38px;
    }
    .pageContainer.auto-renewal .form-field-container > div:not(.form-caption) {
        margin-top: 0;
    }
    .pageContainer.adhoc-detail-page .status-row h1 {
        margin-top: 0;
    }
    .pageContainer.adhoc-detail-page .main-content-box {
        padding: 20px 30px;
    }
}

@media only screen and (max-width: 469px) {
    .auto-renewal .main-content-box {
        padding: 15px !important;
        width: 100% !important;
    }
    .auto-renewal .main-content-box .content-boxed-block  {
        padding: 0;
    }
    .auto-renewal .main-content-box .content-boxed-block:has(.credit-card-details)  {
        padding: 15px;
    }
    .auto-renewal.pageContainer {
        width: 100%;
    }
    .pageContainer.auto-renewal .titleHeader .top-title {
        font-size: 16px;
    }
    .pageContainer.auto-renewal .auto-renewal-back {
        font-size: 14px;
        font-weight: 600;
    }
    .content-boxed-block .info-sub-container {
        padding: 15px;
    }
    .credit-card-fields .form-field-container {
        gap: 20px;
    }
    .centered-title {
        padding: 20px;
        text-align: center;
    }
    .main-content-box .lined-table .content-left {
        font-size: 12px;
        line-height: 16px;
        text-align: left;
    }
    .lined-table .content-right {
        font-size: 13px;
    }
    .main-content-box .content-row > div.content-left {
        width: 40%;
    }
    .main-content-box .content-row > div.content-right {
        width: 60%;
    }
    .pageContainer.auto-renewal .form-field-container .form-caption span {
        font-size: 14px;
    }
    .pageContainer.auto-renewal .btn-container {
        margin-top: 20px;
    }
    .pageContainer.auto-renewal .btn-container button.Button {
        height: 55px;
    }
    .note-top {
        margin-bottom: 15px;
    }
    .pageContainer.adhoc-detail-page .main-content-container-boxed .banner-box {
        padding: 15px 10px;
        gap: 10px;
    }
    .pageContainer.adhoc-detail-page .details-box {
        padding: 15px;
    }
    .ModalContainerClass .ModalContent .modal-body .list-text > ul {
        padding-left: 20px;
    }
    .pageContainer.adhoc-payment-page .main-content-box {
        padding: 20px;
    }
    .pageContainer .main-content-box .form-container label,
    .pageContainer .main-content-box .form-caption span,
    .pageContainer .main-content-box .form-container .MuiFormHelperText-root.Mui-error {
        font-size: 14px;
        line-height: 20px;
    }
    .btn-big {
        min-width: 120px;
    }
}
@media only screen and (max-width: 376px) {
    section.separated .status-row .status-container {
        margin-right: 20px;
    }
    .auto-renewal.pageContainer .container {
        padding-bottom: 0 !important;
    }
    .pageContainer.auto-renewal .titleHeader .top-title {
        left: 40px;
    }
    .pageContainer.auto-renewal .form-field-container:not(:first-child) {
        margin-top: 0;
    }
    .pageContainer.auto-renewal {
        width: 100%;
    }
    .main-content-container-box {
        width: 100%;
    }
}
@media only screen and (max-width: 370px) {
    section.separated .status-row .status-container {
        margin-bottom: 10px;
    }
}