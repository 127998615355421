:root {
    --Inter: "Inter-Regular", "Inter Regular", "InterRegular", "Inter", sans-serif;
    --InterSemiBold: "Inter-SemiBold", "Inter SemiBold", "InterSemiBold", "Inter", sans-serif;
    --InterMedium: "Inter-Medium", "Inter Medium", "InterMedium", "Inter", sans-serif;
    --InterBold: "Inter-Bold", "Inter Bold", "InterBold", "Inter", sans-serif;
    --Stag: "Stag", sans-serif;
    --StagMedium: "Stag-Medium", "Stag Medium", "StagMedium", "Stag", sans-serif;
    --StagBold: "Stag-Bold", "Stag Bold", "StagBold", "Stag", sans-serif;
    --Brand700-base: #003DA5;
    --Blue205: #00205B;
    --containerOffWhite: #f6f8fa;
    --Black900: #0A1F44;
    --obsidian: #000000;
    --black1: #191919;
    --Gray200: #f7f8f9;
    --gray2: #4D515B;
    --gray3: #C9CED6;
    --bannerBoxSucccess: rgba(0,134,90,0.1);
    --bannerBoxSuccess-border: #00865A;
    --bannerBoxBlue: rgba(0,154,228,0.1);
    --bannerBoxBlue-border: #009AE4;
    --whiteBox-border: #E0E3E7;
    --white: #ffffff;
    --black: #000000;
    --lightGray: #8A94A6;
    --boxShadow: rgba(0,0,0,0.07);
    --listTextGray: #4D515B;
    --red: #A50000;
    --redDiffusedBG: rgba(165,0,0,0.1);
    --green: #00865A;
    --greenDiffusedBG: #e6f2ee;
    --blueDiffusedBG: rgba(0,61,165,0.08);
    --mintgreen: #00C457;
    --mintgreenDiffusedBG: rgba(0,77,34,0.08);
    --tertiary900: #00205B;
}

.toggleBlockHeader {
    margin-top: 0;
}
.content-boxed-block + .content-boxed-block.not-first {
    margin-top: 30px;
}
.toggleTable {
    border: 1px solid #c9ced6;
    line-height: 15px;
    border-spacing: 0;
    font-size: 11px;
    margin-bottom: 20px;
}
.content-boxed-block h1,
.content-boxed-block h2,
.content-boxed-block h3 {
    color: var(--Brand700-base);
}
.toggleTable thead {
    background-color: var(--containerOffWhite);
}
.toggleTable td {
    padding: 6px;
}
.toggleTable td {
    border-right: 1px solid var(--gray3);
    border-bottom: 1px solid var(--gray3);
}
.toggleTable td:last-child {
    border-right: 0;
}
.toggleTable tbody tr:last-child td {
    border-bottom: 0;
}
.toggleTable tbody tr.number-col td {
    text-align: right;
}


@media only screen and (max-width: 1024px) {

}
