:root {
    --interR: "Inter-Regular", "Inter Regular", "Inter", sans-serif;
    --interSB: "Inter-SemiBold", "Inter SemiBold", "Inter", sans-serif;
    --interM: "Inter-Medium", "Inter Medium", "Inter", sans-serif;
    --interB: "Inter-Bold", "Inter Bold", "Inter", sans-serif;
    --stagM: "Stag-Medium", "Stag Medium", "Stag", sans-serif;
    --stagR: "Stag", sans-serif;
}

/* ENHANCEMENT - SLIDER STYLES */
.carousel {
    font-family: var(--stagR);
    width: 100%;
    height: fit-content;
    margin: 0 auto;
    padding-top: 60px;
}
.SliderButtonContainer {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.SliderButtonContainer > div {
    display: flex;
    gap: 10px;
}
button.buttonControls {
    position: absolute;
    top: 80px;
    z-index: 2;
    background: none;
    border: 0;
}
.carousel .carousel__slider {
    height: 150px;
}
.carousel .carousel__slider-tray-wrapper .carousel__slider-tray, .carousel__slide {
    display: flex !important;
    justify-content: center;
    z-index: 1;
}
.carousel .carousel__slider-tray-wrapper .carousel__slider-tray .carousel__inner-slide {
    position: unset;
    width: 1040px;
    height: 190px;
    /* background: rgba(0,0,0,0.4); */
    display: flex;
    box-shadow: 5px 5px 15px #ccc;
    border-radius: 20px;
    background-color: rgba(255,255,255,0.8);
}

.carousel .topDots button {
    width: 100px;
    height: 100px;
}

/* .carousel .carousel__slider-tray-wrapper .carousel__slider-tray .carousel__slide:first-of-type {background: #78bbed;}
.carousel .carousel__slider-tray-wrapper .carousel__slider-tray .carousel__slide:nth-of-type(2) {background: #62abe2;}
.carousel .carousel__slider-tray-wrapper .carousel__slider-tray .carousel__slide:nth-of-type(3) {background: #4d9cd7;}
.carousel .carousel__slider-tray-wrapper .carousel__slider-tray .carousel__slide:nth-of-type(4) {background: #3d8bc5;}
.carousel .carousel__slider-tray-wrapper .carousel__slider-tray .carousel__slide:nth-of-type(5) {background: #2b78b2;}
.carousel .carousel__slider-tray-wrapper .carousel__slider-tray .carousel__slide:nth-of-type(6) {background: #20669a;}
.carousel .carousel__slider-tray-wrapper .carousel__slider-tray .carousel__slide:nth-of-type(7) {background: #165381;}
.carousel .carousel__slider-tray-wrapper .carousel__slider-tray .carousel__slide:nth-of-type(8) {background: #0e3f64;} */

.sliderContainerScroller {
    position: relative;
    width: 100%;
    height: 190px;
}
.sliderContainerScroller .carousel__slider {
    overflow: unset;
}
button.carousel__back-button {
    left: calc(((100vw - 1040px)/2) - 100px);
}
button.carousel__next-button {
    right: calc(((100vw - 1040px)/2) - 100px);
}
.topControlsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
}
.topControlsContainer .topControls {
    width: 1040px;
    display: flex;
    justify-content: space-between;
    gap: 0;
}
.topControlsContainer .topControls button {
    width: 20%;
    padding: 0 30px;
    background: none;
    border: 0;
}
.topDotContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}
.topControlsContainer .topControls button:not(:last-child) {
    border-right: 1px solid #ccc;
}

.CarouselImg img {
    width: 400px;
}
ul.CarouselDetailsList {
    list-style-type: none;
}
ul.CarouselDetailsList li::marker {
    margin-top: 2px;
}
ul.CarouselDetailsList li {
    margin: 5px 0 10px 0;
    padding-left: 10px;
    font-family: var(--interR);
    font-size: 16px;
}
ul.CarouselDetailsList li:empty {
    display: none;
}
.sampleDetails {
    width: 100%;
    padding: 30px;
}
.sampleDetails .sampleDetailsTitle h2 {
    margin: 0;
    font-family: var(--stagR) !important;
    font-size: 24px;
    letter-spacing: 1px;
    font-weight: 500
}
.sampleDetailsTitle{
    padding-bottom: 10px;
}
.sampleDetails .sampleDetailsDescription{
    font-family: Inter, sans-serif;
    font-size: 18px;
}
.SliderButtonContainer .carousel__dot-group button {
    padding: 0;
    width: 10px;
    height: 10px;
    border-radius: 20px;
    border: 0;
    background: #e1e4e8;
}
.SliderButtonContainer .carousel__dot-group button.carousel__dot--selected {
    background: dimgrey;
}
.topControlsTitle {
    font-size: 14px;
    font-weight: bold;
    font-family: var(--interR);
    line-height: 18px;
}
.topControls .carousel__dot--selected .topControlsTitle {
    color: #003DA5;
}
.carousel__dot--selected .topDotContainer svg > g > g {
    fill: #003DA5;
}

@media (max-width: 1024px) {
    /* Carousel mobile styles */
    .HKG-template .carousel .topControls {
        flex-direction: column;
        align-items: center;
        padding: 0 50px;
    }
    .HKG-template .carousel .topControls button.carousel__dot {
        border-right: 0;
        width: 100%;
        padding: 0;
    }
    .HKG-template .carousel .topControls button.carousel__dot .topDotContainer {
        flex-direction: row;
        margin: 6px 0;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
        justify-content: flex-start;
    }
    .HKG-template .carousel .topControls button.carousel__dot:last-child .topDotContainer {
        border-bottom: 0;
    }
    .HKG-template .carousel .topControls button .topDotContainer svg {
        width: 24px;
        height: 24px;
    }
    .HKG-template .carousel__slide {
        padding: 0 20px 0 20px;
    }
    .HKG-template .carousel .carousel__slider-tray-wrapper .carousel__slider-tray .carousel__inner-slide {
        width: 100% !important;
        height: auto;
        flex-direction: column;
    }
    .HKG-template .carousel .sampleDetailsTitle {
        display: none;
    }
    .HKG-template .CarouselImg img {
        width: 100%;
    }
    .HKG-template .carousel .carousel__slider {
        height: 391px;
    }
    .HKG-template .sampleDetails {
        width: auto;
        padding: 10px 0 0 5px;
    }
    .HKG-template .sampleDetails .CarouselDetailsList {
        margin: 0;
    }
    .HKG-template button.carousel__next-button {
        right: 25%;
        top: 410px;
    }
    .HKG-template button.carousel__back-button {
        left: 25%;
        top: 410px;
    }
    .topControlsContainer {
        margin-bottom: 10px;
    }
}
.CarouselDetailsList a {
    color: black;
    text-decoration: underline;
}
.sampleDetailsDescription button {
    float: right;
    height: 35px;
}
.carousel__slide-focus-ring {
    outline: none !important;
}
@media only screen and  (max-width: 767px) {
    .carousel .carousel__slider-tray-wrapper .carousel__slider-tray .carousel__inner-slide {
        background-color: rgba(255,255,255,0.9);
        height: 300px;
        width: 90%;
    }
    .sliderContainerScroller {
        height: 300px;
    }
    .SliderButtonContainer {
        height: 40px;
    }
    .carousel .carousel__slider {
        height: 300px
    }
    .sliderContainerScroller .carousel__slider {
        overflow: hidden
    }
}
@media screen and (min-width: 768px) and (max-width: 1198.98px) {
    .carousel .carousel__slider-tray-wrapper .carousel__slider-tray .carousel__inner-slide {
        background-color: rgba(255,255,255,0.9);
        box-shadow: none;
        height: 300px;
        width: 90%;
    }
    .carousel {
        padding-top: 0;
    }
    .sliderContainerScroller {
        height: 300px;
    }
    .carousel .carousel__slider {
        height: 300px;
    }
    .sliderContainerScroller .carousel__slider {
        overflow: hidden;
    }
    .SliderButtonContainer {
        height: 50px;
    }
}
