.ModalBody,
#TermsConditions .MuiDialog-paper,
#TermsConditionsDetail .MuiDialog-paper,
#PICS .MuiDialog-paper {
    padding: 30px 40px;
}
#PICS .MuiDialog-paper {
    width: unset;
}
#TermsConditions .TermsConditionsModal .content,
#PICS .PICS_Modal .content {
    margin-top: 0;
}
#TermsConditionsFull.TermsAndConditionsFull_Modal .content {
    margin-top: 20px;
}
.ModalBody .NeedHelp_Modal {
    line-height: 25px;
}
#popupContainerContent .ModalContent {
    padding-left: 20px;
    padding-right: 20px;
}
#popupContainerContent .NeedHelp_Modal {
    width: 490px;
}
#popupContainerContent .NeedHelp_Modal .content {
    margin-bottom: 10px;
}
.NeedHelp_Modal .contactForm {
    color: #003DA5;
}
.Modal .content a,
.Modal .content a:hover,
.Modal .content a:link,
.Modal .content a:visited,
.MuiDialog-container .content a,
.MuiDialog-container .content a:hover,
.MuiDialog-container .content a:visited,
.MuiDialog-container .content a:link {
    color: #003DA5;
}
#TermsConditions .TermsAndConditions_Modal .content ol li.terms-link {
    color: #003DA5;
    font-family: "Inter-Semibold", "Inter Semibold", "Inter", sans-serif;
    cursor: pointer;
}
#TermsConditions .TermsAndConditions_Modal .content ol li::before {
    color: #0A1F44;
    font-family: "Inter-Regular", "Inter Regular", "Inter", sans-serif;
}
#TermsConditions .TermsAndConditions_Modal .content {
    margin-top: 0;
}
.NeedHelp_Modal .contactEmail {
    margin-top: 40px;
    margin-bottom: 10px;
}
.NeedHelp_Modal .content,
.NeedHelp_Modal .contactForm,
.NeedHelp_Modal .contactEmail {
    font-size: 18px;
    font-family: "", "Inter Regular", "Inter", sans-serif !important;
    line-height: 25px;
}
.NeedHelp_Modal .contactEmail a {
    color: #0A1F44;
}
.NeedHelp_Modal .contactForm a {
    color: #003DA5;
}
.NeedHelp_Modal .contactForm {
    font-family: "Inter-Semibold", "Inter Semibold", "Inter-Regular", "Inter Regular", "Inter", sans-serif !important;
}
.TermsAndConditions_Modal .content,
.TermsAndConditions_Modal .content ol {
    font-size: 18px;
    font-family: "", "Inter Regular", "Inter", sans-serif !important;
    line-height: 25px;
}
.TermsAndConditions_Modal .content {
    margin-bottom: 0 !important;
}
.TermsAndConditionsFull_Modal .title,
.PICS_Modal .title,
#TermsConditionsDetail h2.MuiTypography-root {
    display: flex;
    justify-content: center;
    align-items: center;
}
#TermsConditionsDetail h2.MuiTypography-root {
    margin-left: -20px;
}
.TermsAndConditionsFull_Modal .title img,
.PICS_Modal .title img {
    width: 14px;
    margin-right: 20px;
}
.TermsAndConditions_Modal ol,
.PICS_Modal ol.mainList2 {
    padding-left: 0;
    counter-reset: list;
}
#PICS .content,
#TermsConditionsDetail .content {
    max-height: 500px;
    overflow-y: scroll;
}
#PICS .MuiDialogTitle-root > h2 > div:first-child > div {
    align-items: center;
}
ol.mainList li,
ol.mainList2 li {
    padding-right: 10px;
}
.TermsAndConditions_Modal ol > li,
.PICS_Modal ol.mainList2 > li {
    list-style: none;
    position: relative;
    padding-left: 25px;
}
.TermsAndConditions_Modal ol > li:before,
.PICS_Modal ol.mainList2 > li:before {
    content: counter(list, lower-alpha) ") ";
    counter-increment: list;
    position: absolute;
    top: 0;
    left: 0;
}
.TermsAndConditions_Modal .content ol li a,
.UserError_Modal .content a {
    color: #003DA5;
    text-decoration: none;
}
.TermsAndConditions_Modal .listTitle {
    margin-bottom: 0;
    padding-bottom: 0;
    line-height: 28px;
}
.TermsAndConditions_Modal .content,
.TermsAndConditionsFull_Modal .content,
.PICS_Modal .content {
    text-align: left;
    line-height: 24px;
}
.TermsAndConditionsFull_Modal .mainList {
    padding-left: 30px;
}
.TermsAndConditionsFull_Modal .mainList li {
    margin-bottom: 10px;
}
.TermsAndConditionsFull_Modal .mainList li::marker {
    margin-left: 0;
}
.TermsAndConditionsFull_Modal ol li.mainItemTitle .mainItemTitle-Title {
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
}
.PICS_Modal ol li.mainItemTitle .mainItemTitle-Title {
    margin-bottom: 10px;
    margin-top: 20px;
}
.TermsAndConditionsFull_Modal .itemText {
    margin-left: -20px;
}
.TermsAndConditionsFull_Modal .subList {
    margin-left: -38px;
}
.TermsAndConditionsFull_Modal .subList li {
    list-style: disc;
}
.PICS_Modal ol.mainList2 {
    padding-left: 0;
}
.PICS_Modal ol.subList {
    padding-left: 20px;
}

#PICS .mobile-only {
    display: none;
}
#PICS .desktop-only {
    display: block;
}


.ModalSGP .TermsAndConditions_Modal button.agreeButton,
#TermsConditions .TermsAndConditions_Modal button.agreeButton {
    padding-left: 55px !important;
    padding-right: 55px !important;
    margin-bottom: 16px;
    background: #1D4FBB;
}
.ModalSGP .TermsAndConditions_Modal button.agreeButton:hover,
#TermsConditions .TermsAndConditions_Modal button.agreeButton:hover {
    background: #003DA5;
}
.ModalSGP .UserError_Modal button.okButton {
    padding-left: 55px !important;
    padding-right: 55px !important;
    margin-bottom: 16px;
}
.UserError_Modal .content {
    line-height: 25px;
}



#popupContainerContent .modalCloseButton {
    top: 22px;
    right: 25px;
}
#popupContainerContent .modalCloseButton > svg {
    fill: #A7AEBB !important;
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
}

@media (max-width: 1024px) {
    #SGLogin #SGLoginTabPanel {
        width: unset;
    }
    #SGTitle {
        margin-top: 25px;
        font-size: 18px;
        margin-bottom: 30px;
    }
    #login_sub_title {
        font-size: 16px;
        line-height: 24px;
    }
    #bottom_part {
        width: unset;
    }
    #no_singpass {
        margin-top: 50px;
    }
    #popupContainerContent .NeedHelp_Modal {
        width: 100%;
    }
    #popupContainerContent .modalCloseButton > svg {
        width: 32px !important;
        height: 32px !important;
        min-width: 32px !important;
        min-height: 32px !important;
    }
    #PICS .MuiDialogTitle-root svg {
        width: 28px !important;
        height: 28px !important;
        min-width: 28px !important;
        min-height: 28px !important;
        margin-top: 0;
    }
    #popupContainerContent .modalCloseButton {
        top: 10px;
        right: 10px;
    }
    .ModalBody {
        padding: 20px;
    }
    #NeedHelp .title,
    #UserError .title,
    #TermsConditions .title,
    #TermsConditionsFull .title,
    #PICS .title,
    #PICS .MuiDialogTitle-root {
        font-size: 20px;
        margin-top: 0;
        line-height: 24px;
        color: #003DA5;
    }
    #PICS .MuiDialogTitle-root > h2 > div:first-child > div > div:nth-child(n+1) {
        font-size: 20px;
        margin-top: 0;
        line-height: 24px;
        color: #003DA5;
    }
    #PICS .MuiDialogTitle-root {
        padding-top: 0;
        padding-bottom: 0;
    }
    #NeedHelp.NeedHelp_Modal .content,
    #UserError.UserError_Modal .content,
    #TermsConditions .TermsAndConditions_Modal .content,
    #TermsConditionsDetail .TermsAndConditionsFull_Modal .content,
    #PICS .PICS_Modal .content,
    #NeedHelp.NeedHelp_Modal .contactEmail {
        margin-top: 20px;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 0;
    }
    #NeedHelp .contactForm {
        font-size: 16px;
    }
    #NeedHelp.NeedHelp_Modal .contactEmail {
        margin-top: 30px;
    }
    #UserError.UserError_Modal button.okButton,
    #TermsConditions button.agreeButton {
        min-width: 150px;
        height: 55px;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
    #popupContainerContent .ModalContent {
        padding-left: 0;
        padding-right: 0;
    }
    #TermsConditions .TermsAndConditions_Modal .content ol,
    #TermsConditionsDetail.TermsAndConditionsFull_Modal .content ol,
    #PICS.PICS_Modal .content ol,
    #TermsConditions .TermsAndConditions_Modal .content ul,
    #TermsConditionsDetail .TermsAndConditionsFull_Modal .content ul,
    #PICS.PICS_Modal .content ul {
        font-size: 16px;
        line-height: 22px;
    }
    #TermsConditions .TermsAndConditions_Modal .content ol li,
    #TermsConditionsDetail .TermsAndConditionsFull_Modal .content ol li,
    #PICS.PICS_Modal .content ol li,
    #TermsConditions.TermsAndConditions_Modal .content ul li,
    #TermsConditionsDetail .TermsAndConditionsFull_Modal .content ul li,
    #PICS.PICS_Modal .content ul li {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    #TermsConditions .TermsAndConditions_Modal .content ol li a,
    #TermsConditionsDetail .TermsAndConditionsFull_Modal .content ol li a,
    #PICS.PICS_Modal .content ol li a,
    #TermsConditions .TermsAndConditions_Modal .content ul li a,
    #TermsConditionsDetail .TermsAndConditionsFull_Modal .content ul li a,
    #PICS.PICS_Modal .content ul li a {
        font-family: "Inter-Semibold", "Inter Semibold", "Inter-Regular", "Inter Regular", "Inter", sans-serif;
    }
    #TermsConditions .TermsAndConditions_Modal .content ol li:first-child,
    #TermsConditionsDetail .TermsAndConditionsFull_Modal .content ol li:first-child,
    #PICS.PICS_Modal .content ol li:first-child,
    #TermsConditions .TermsAndConditions_Modal .content ul li:first-child,
    #TermsConditionsDetail .TermsAndConditionsFull_Modal .content ul li:first-child,
    #PICS.PICS_Modal .content ul li:first-child {
        margin-top: 0;
    }
    .TermsAndConditionsFull_Modal .title img, .PICS_Modal .title img {
        margin-right: 10px;
    }
    #TermsConditionsFull .title, 
    #PICS .title {
        margin-left: -10px;
    }
    #PICS .mobile-only {
        display: block;
    }
    #PICS .desktop-only {
        display: none;
    }
    #PICS.PICS_Modal .content ol.mainList2 {
        margin-top: 0;
    }
    .PICS_Modal ol li.mainItemTitle .mainItemTitle-Title {
        margin-top: 0;
    }
    #PICS .MuiDialogTitle-root {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    #PICS .MuiDialogTitle-root > h2 > div:first-child > div {
        align-items: center;
    }
}

@media (max-width: 767px) {
    .ModalBody,
    #TermsConditions .MuiDialog-paper,
    #TermsConditionsDetail .MuiDialog-paper,
    #PICS .MuiDialog-paper {
        padding: 20px !important;
    }
    #TermsConditionsFull .content,
    #TermsConditions .content,
    #PICS .content {
        padding-right: 10px;
    }
    #TermsConditions .MuiDialogTitle-root {
        padding: 0 0 10px 0;
    }
    #TermsConditions .TermsAndConditions_Modal .content div:last-child {
        margin-top: 30px;
    }
}

@media (min-width: 767px) and (max-width: 1199px) {
    #right_content {
        width: 450px;
    }
    #SGLogin > div > div > div:first-child {
        margin-top: 20px;
    }
    #SGLogin > div > div > div:nth-child(2) {
        margin-top: 0;
    }
    #SGLogin > div > div > div:nth-child(3) #bottom_part > div:nth-child(2) {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }
    #SGLogin #bottom_part {
        bottom: 80px !important;
    }
    #login_sub_title {
        margin-top: 15px;
    }
    #PICS .mobile-only {
        display: none;
    }
    #PICS .desktop-only {
        display: block;
    }
    #popupContainerContent #NeedHelp.NeedHelp_Modal,
    #popupContainerContent #UserError.UserError_Modal {
        width: 450px;
    }
    #popupContainerContent {
        padding-bottom: 20px;
    }
    #TermsConditions .MuiDialog-paper,
    #TermsConditionsDetail .MuiDialog-paper,
    #PICS .MuiDialog-paper {
        width: unset;
    }
}

@media (min-width: 1025px) and (max-width: 1199px) {
    #no_singpass {
        margin-top: 30px;
    }
}

iframe {
    display: none;
}
