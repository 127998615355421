@media (max-width: 767px) {
    #footer_container {
        padding-left: 10px;
        padding-right: 10px;
    }
    #footer_container > div:first-child {
        text-align: center;
    }
    #footer_links_container {
        flex-wrap: wrap;
    }
    #footer_links_container > div {
        width: 100%;
        justify-content: center;
        text-align: center;
    }
    #footer_links_container > div:nth-child(2) > a {
        margin-left: 5px;
        margin-right: 5px;
    }
}