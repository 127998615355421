@-moz-document url-prefix() {
    #flexible-content{
        flex: 1;
    }
}
.pageContainer {
    position: relative;
}
.container {
    width: 1440px;
    margin: 0 auto;
}
@media (max-width:1199.95px) {
    .container {
        width: 100vw;
        margin: 0 auto;
    }
}
p {font-family: "Inter-Regular", sans-serif;}

/*--- TOP ROW - START ---*/
.topRow {
    /*padding: 20px 40px;*/
    margin: 40px 166px 40px 166px;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
.topRow .left {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: 50%;
}
.topRow .right {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -moz-box-align: center;
    align-items: center;
    flex-direction: row;
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -moz-box-direction: normal;
    -moz-box-orient: horizontal;
    width: 50%;
}
.logoDiv {
    width: 122px;
    height: 35px;
}
.languageSelect {
    color: #A7AEBB;
    /*font-weight: bold;*/
    font-size: 18px;
    /*margin-right: 40px;*/
    cursor: pointer;
}
.button-back {
    margin-left: 167px;
    margin-top: 40px;
}
.button-back button {
    border: 0;
    outline: none;
    background-color: transparent;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -moz-box-align: center;
    align-items: center;
}
.button-back .back-icon {
    width: 15px;
    height: 24px;
    margin-right: 14px;
}
.button-back .back-text {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -moz-box-align: center;
    align-items: center;
    font-family: "Inter-Medium", sans-serif;
    color: #1D4FBB;
    font-size: 20px;
    line-height: 28px;
}
.main-page-title {
    margin-top: 40px;
    margin-left: 167px;
    margin-right: 167px;
}
.main-page-title span {
    font-family: "Stag-Medium", sans-serif;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 1px;
    color: #003DA5;
}

@media (max-width:450px) {
    .topRow {
        margin: 40px 0;
        justify-content: center;
    }
    .topRow .left {
        width: fit-content;
    }
}
/*--- TOP ROW - END ---*/


/*--- MAIN CONTAINER ELEMENTS - START ---*/
.main-content-container-boxed {
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
}
.main-content-container-box {
    width: 600px;
}
.main-content-box {
    border-radius: 20px;
    background-color: #FFFFFF;
    box-shadow: 0 0 60px 0 rgba(0,0,0,0.06);
    padding: 40px 60px;
    margin-top: 30px;
}
.main-content-title {
    margin: 30px 20px;
    font-family: "Stag-Medium", sans-serif;
    color: #0A1F44;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
}
.main-description-text {
    font-family: "Inter-Regular", sans-serif;
    color: #0A1F44;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
}
.main-description-text a {
    font-weight: bold;
    color: #000;
    text-decoration: none;
}
.thankyou-message {
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -moz-box-align: center;
    align-items: center;
    margin-bottom: 30px;
    text-align: center;
}
.main-content-box.no-topTitle {
    margin-top: 50px;
}
.main-content-box p, .main-content-box ul, .main-content-box ol {
    font-size: 16px;
    line-height: 24px;
    color: #0A1F44;
}
.main-content-box p.list-title {
    padding-bottom: 0;
    margin-bottom: 0;
}
.main-content-box ol.with-list-title, .main-content-box ul.with-list-title {
    margin-top: 0;
    padding-top: 0;
}
.main-content-box .content-sub-title {
    font-size: 16px;
    font-family: "Inter-Medium", "Inter-Regular", "Inter", sans-serif;
    line-height: 24px;
    color: #003DA5;
}
.main-content-box.smaller-padding {
    padding: 30px !important;
}
.main-content-box.smaller-padding p:first-child,
.main-content-box.smaller-padding ul:first-child,
.main-content-box.smaller-padding ol:first-child {
    margin-top: 0;
}
.main-content-box.smaller-padding p:last-child,
.main-content-box.smaller-padding ul:last-child,
.main-content-box.smaller-padding ol:last-child {
    margin-bottom: 0;
}
@media (max-width:450px) {
    .main-content-container-box {
        width: 100%;
    }
    .main-content-box {
        padding: 40px;
    }
}

/*--- MAIN CONTAINER ELEMENTS - END ---*/

/*--- PAYMENT INFO ELEMENTS - START ---*/
.highlight {
    background-color: #EDEFF9;
    border-radius: 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -moz-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 25px 60px;
}
.highlight-text {
    font-family: "Inter-Medium", sans-serif;
    color: #0A1F44;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
}
.main-highlight-text {
    font-family: "Inter-Medium", sans-serif;
    color: #0A1F44;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    margin: 10px 0;
}
.pricing-row {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
}
.pricing-block {
    width: 350px;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -moz-box-align: center;
    align-items: center;
    flex-direction: column;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-direction: normal;
    -moz-box-orient: vertical;
}
.credit-cards {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -moz-box-direction: normal;
    -moz-box-orient: horizontal;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 200px;
}
.c-card {
    width: 76px;
    height: 44px;
    border: 0;
    border-radius: 2px;
    background-color: #FFFFFF;
    /*box-shadow: 0 2px 3px 0 rgba(0,0,0,0.1);*/
    margin: 10px;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -moz-box-align: center;
    align-items: center;
    outline: none;
}
/*--- PAYMENT INFO ELEMENTS - END ---*/



/*--- FORM STYLES - START ---*/
.form-container {
    width: 100%;
}
.form-field-container {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 40px;
}
.form-field-container label {
    font-family: "Inter-Regular", sans-serif;
    font-size: 18px;
    line-height:25px;
    color: #0A1F44;
    margin: 10px 0;
    display: block;
}
.form-field-container input {
    border: 0;
    outline: none;
    /*appearance: none;*/
    font-family: "Inter-Regular", sans-serif;
    font-size: 18px;
    color: #0A1F44;
}
.form-field-container > div {
    width: 100%;
}
.form-field-container .form-caption span {
    color: #8A94A6;
    font-family: "Inter-Regular", sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin: 10px 0;
}
.form-field-checkbox {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 40px;
}
.form-field-checkbox > div:not(.form-caption) {
    padding-bottom: 0;
    margin-bottom: 10px;
}

.two-column .form-field-container {
    width: 50%;
    margin-bottom: 20px;
}
.two-column .form-field-container input {
    width: 180px;
}
.icon-info-container {
    position: relative;
}
.with-ccv-icon .BalloonTip {
    position: absolute;
    top: -18px;
    left: 48px;
}
.with-ccv-icon.chi .BalloonTip {
    left: 78px;
}

.button-container {
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -moz-box-align: center;
    align-items: center;
    margin-top: 20px;
}
.dual-button-foot {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 30px;
}
.dual-button-foot button:first-of-type {
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-right: 10px;
}
.dual-button-foot button:last-of-type {
    -webkit-box-pack: end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-left: 10px;
}
.form-field-container .MuiInputLabel-formControl {
    top: -45px;
}
.form-field-container .MuiInputLabel-filled, .form-field-container .MuiInputLabel-filled .MuiInputLabel-shrink {
    transform: none !important;
}
.form-field-container .MuiFilledInput-input {
    /*padding: 18px 20px;*/
    /*border: 1px solid #C9CED6;*/
    /*border-radius: 12px;*/
    border: 0 !important;
}
.form-field-container .MuiFilledInput-underline:before,
.form-field-container .MuiFormControl-root .MuiFilledInput-underline::after,
.form-field-container .MuiFilledInput-underline:hover:before {
    border: none;
}
.form-field-container .MuiFormControl-root .MuiInputBase-root {
    background-color: transparent !important;
}
.form-field-container .MuiFormLabel-root.Mui-focused {
    color: #0A1F44 !important;
}
.form-field-container > div:not(.form-caption) {
    padding-bottom: 0;
    margin-bottom: 10px;
}
.form-field-container .MuiInputAdornment-positionEnd {
    position: absolute;
    top: 29px;
    right: 20px;
}
.form-field-container.button-container button {
    font-size: 20px;
    line-height: 28px;
    border-radius: 35px;
    background-color: #1D4FBB;
}

.form-field-container.button-container button:disabled {
    background-color: #B0B7C3;
    box-shadow: none;
}

.form-field-container.button-container button {
    font-size: 20px;
    line-height: 28px;
    border-radius: 35px;
    background-color: #1D4FBB;
}
.form-field-container.button-container .Button:hover:enabled {
    background-color: #002185;
}
.form-field-container.button-container.button-minor1 Button:hover:enabled {
    background-color: #e1e4e8;
}
.form-field-container.button-container button.light {
    background-color: #fff;
}
.form-field-container.button-container button[aria-disabled="disabled"] {
    background-color: #B0B7C3;
    box-shadow: none;
    color: #fff;
}
.form-field-container.button-container.inactive button {
    background-color: #B0B7C3;
    box-shadow: none;
}
.form-field-container.button-container.button-size1 button {
    width: 350px;
    height: 70px;
}
.form-field-container.button-container.button-size2 button {
    width: 290px;
    height: 70px;
}
.form-field-container.button-container.button-size3 button {
    width: 160px;
    height: 70px;
}
.form-field-container.button-container.button-minor1 button {
    width: 190px;
    height: 34px;
    font-size: 12px;
    text-align: right;
    background-color: #fafbfb;
    color: #003DA5;
    border: #1D4FBB solid 2px;
}
span.status-label {
    width: 700px;
}

.form-field-container.button-container > div {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.form-field-container.button-container {
    margin-top: 0;
}
.with-ccv-icon .BalloonTip .contentDiv {
    top: 25px !important;
    min-width: 260px !important;
    border-radius: 3px;
}
.form-field-container .MuiFormControl-root .MuiInputBase-root.Mui-disabled {
    background: #fafbfb !important;
}
.form-field-container .MuiFilledInput-underline.Mui-disabled:before {
    border-bottom: none;
}
.form-field-container .MuiFilledInput-input.Mui-disabled {
    color: #929bac;
}
@media (max-width:450px) {
    #online-payment-credit-card-details-page .two-column .form-field-container label {
        font-size: 14px;
    }
    .two-column .form-field-container {
        width: calc(50% - 10px);
    }
}

/*--- FORM STYLES - END ---*/


/*--- TABLE STYLES - START ---*/
.table-details {

}
.table-details table {
    width: 100%;
}
.table-details th {
    text-align: left;
    padding: 10px;
    border-bottom:  1px solid #E1E4E8;
    color: #003DA5;
    font-size: 14px;
    line-height: 20px;
    font-family: "Inter-Regular", sans-serif;
}
.table-details table td {
    padding: 20px 10px 0 10px;
}
.table-details table tbody td:first-child {
    width: 35%;
    color: #8A94A6;
}
.table-details table tbody {
    font-family: "Inter-Regular", sans-serif;
    font-size: 16px;
    line-height: 20px;
}
/*--- TABLE STYLES - END ---*/


/*--- PAGE FOOT STYLES - START ---*/
.page-footer {
    background: #191919;
    width: 100%;
    display: flex;
    margin-top: 40px;
    position: absolute;
    bottom: -20px;
}
.page-footer-links {
    padding: 10px 165px;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
.page-footer .terms-links ul {
    list-style: none;
    color: #fff;
    font-family: "Inter-Regular", sans-serif;
    font-size: 16px;
    line-height: 20px;
    padding-left: 0;
}
.page-footer .terms-links ul li {
    float: left;
    margin-right: 20px;
}
.page-footer-links {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.terms-links, .social-links {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -moz-box-align: center;
    align-items: center;
    width: 50%;
}
.social-links {
    -webkit-box-pack: end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.terms-links a {
    text-decoration: none;
    color: #fff;
}
.social-links .social-link {
    border-radius: 20px;
    height: 32px;
    width: 32px;
    background-color: #474747;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -moz-box-align: center;
    align-items: center;
}
.social-link a {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -moz-box-align: center;
    align-items: center;
}
.social-link {
    margin-right: 20px;
}
.social-link:last-child {
    margin-right: 0;
}
/*--- PAGE FOOT STYLES - END ---*/


/*--- GENERIC STYLES - START ---*/
.alignCenter {
    text-align: center;
}
.two-column {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
.two-column > div:first-child {
    margin-right: 10px;
}
.two-column > div:last-child {
    margin-left: 10px;
}
.no-topMargin {
    margin-top: 0;
}
/*--- GENERIC STYLES - END ---*/

/*--- PAY CONFIRM SECTION / adjustments ---*/
#pay_confirm_section {
    /*background: #191919;*/
}
.adhoc-detail-page > .container {
    padding-bottom: 60px;
}
.adhoc-payment-page > .container {
    padding-bottom: 40px;
}
.adhoc-payment-finish-page > .container {
    padding-bottom: 60px;
}
.renewal-detail-page > .container {
    padding-bottom: 40px;
}
.renewal-finish-page > .container {
    padding-bottom: 60px;
}

.policy-details {
    margin-bottom: 44px;
}

.PaymentGatewayIframeVisible {
    background: white;
    border: none;
    min-width: 500px;
    min-height: 400px;
    overflow: scroll;
    display: block;
}

.PaymentGatewayIframeHidden {
    width: 500px;
    height: 0px;
    display: none;
}

.payment-option-title {
    margin: 0 0 28px 0;
    font-family: "Inter-Medium", sans-serif;
    color: #00205B;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
}

.payment-option-section {
    margin: 8px 20px 48px;
    height: 224px;
}

.form-field-container .payment-caption {
    height: 25px;
    color: #0A1F44;
    font-family: "Inter-Regular", sans-serif;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
}

.form-field-container .qr-code {
    text-align: center;
}

.payment-option-radio {
    height: 150px;
}

.payment-option-section .RadioBoxGroupsStyle {
    display: flex;
    align-items: center;
}

.payment-option-section .RadioGroupStyle {
    width: 124px;
}

.payment-option-section .RadioCard {
    height: 164px;
}

.payment-option-section .RadioButton {
    position: relative;
    left: 35px;
    top: 100px;
}

.payment-option-section .SelectCardLabel {
    position: relative;
    left: -30px;
    height: 36px;
    width: 75px;
    color: #000000;
    font-family: Inter;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
}

.form-field-container.bulkPayment-field label.MuiFormLabel-root.Mui-disabled {
    color: #0a1f44 !important;
}

.CCDetailsDisclaimerCheckBox .Checkbox {
    float: left;
    appearance: auto;
}

.CCDetailsDisclaimerCheckBox .LabelText{
    margin-left: 35px;
}

#topAlert {
    margin-top: 40px;
}
.hgi-alert-container {
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    gap: 20px;
}
.hgi-alert-container.info-alert {
    font-size: 14px;
    font-family: var(--InterMedium);
    line-height: 18px;
}
.hgi-alert-container.info-alert::before {
    content: url("../icon_warning.svg");
    width: 30px;
    height: 30px;
    line-height: 0;
    display: flex;
}
.hgi-alert-container.info-alert {
    border: 1px solid var(--green);
    background-color: var(--greenDiffusedBG);
}

@media screen and (max-width: 376px) {
    .pageContainer {
        width: 375px;
    }
    .container {
        width: 375px;
        padding-bottom: 20px !important;
    }
    .main-page-title {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;
    }

    .main-content-title {
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .main-content-container-box {
        width: 375px;
    }
    .topRow {
        padding: 10px 20px !important;
        border-bottom: 1px solid #bbbbbb;
    }
    .topRow .left img {
        width: 81px;
        height: auto;
    }
    .main-content-box {
        width: 355px;
        margin: 0 auto;
        padding: 30px 20px 30px 20px !important;
    }
    .form-field-container label {
        font-size: 16px;
        font-weight: 600;
    }
    .kPzEcx.MuiFormControl-root .MuiFilledInput-input {
        font-size: 16px;
        font-weight: 600;
        padding: 10px;
        height: 60px;
    }
    .form-field-container {
        margin-top: 50px;
    }
    .form-field-container:first-child {

    }
    .form-container {
        margin-top: -20px;
    }
    .form-field-container.button-container.button-size1 button {
        font-size: 18px;
        padding: 10px 25px;
        min-width: 220px;
        height: 60px;
        width: auto;
    }
    .c-card {
        width: 120px;
        height: auto;
    }
    .c-card img {
        width: 120px;
        height: auto;
    }
    .two-column .form-field-container label {
        font-size: 14px;
    }
    .with-ccv-icon .BalloonTip {
        top: -20px;
        left: 38px;
    }
    .main-content-box .table-details table tbody tr {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        padding-top: 10px;
    }
    .main-content-box .table-details table tbody tr td {
        width: 100%;
        display: block;
    }
    .table-details tbody td {
        padding-top: 10px;
    }
    .two-column .form-field-container {
        width: 47%;
    }
    .policy-details {
        margin-bottom: 0;
    }
    .makeStyles-topic-2 a:link, .makeStyles-topic-2 a:visited, .makeStyles-topic-2 a:active {
        color: #fff;
    }
    .makeStyles-topic-2 a:hover {
        color: #999;
    }

}
